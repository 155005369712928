import styled from "@emotion/styled";
import NavigationButton from "../../elements/NavigationButton";
import { useAppDispatch } from "../../../../hooks";
import { CommonButtonDiv, RedButtonDiv } from "../../elements/Buttons";
import t from "../../../../helper/t";
import { startMeasurement3 } from "../../measurement/startMeasurement3";
import { updateResultPageTab } from "../../../../features/pageManager/pageManagerSlice";
import { ResultTabKey } from "../../../../features/pageManager/pageState";

import checkSvg from "../../../../../assets/icons/splus3/check.svg";

const RootDiv = styled.div(({ theme }) => ([
    {
        display: "flex",
        flexDirection: "column",
        flexGrow: 10,
        padding: 20,

        [theme.splus3.breakpoints.mobile]: {
            padding: 16,
        },
    }
]));

const HeaderDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.black,
    theme.splus3.typography.font24Bold,
    {
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.largeBold,
        ],
    }
]));

const BodyDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.defaultLite,
    {
        marginTop: 8,
        lineHeight: "28px",

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font16Lite,
            {
                lineHeight: "24px",
            }
        ],
    }
]));

const SubHeaderDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.black,
    theme.splus3.typography.largeBold,
]));

const ListDiv = styled.div(({ theme }) => ([
    {
        marginTop: 32,
        display: "flex",
        flexDirection: "column",
        rowGap: 16,
    }
]));

const ItemDiv = styled.div(({ theme }) => ([
    theme.splus3.foreground.monochrome600,
    theme.splus3.typography.default,
    {
        display: "flex",
        columnGap: 16,
        lineHeight: "20px",

        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font16,
            {
            }
        ],
    }
]));

const ButtonContainer = styled.div(({ theme }) => ([
    {
        marginTop: 28,
        display: "flex",
        flexDirection: "column",
        rowGap: 20,
        maxWidth: 360,

        [theme.splus3.breakpoints.mobile]: [
            {
                marginTop: 22,
                rowGap: 12,
            }
        ],
    }
]));

const BulletImg = styled.img(() => ([
    { width: "10px" }
]));

interface GuideCardProps {
}

const GuideCard: React.FC<GuideCardProps> = () => {
    const dispatch = useAppDispatch();

    const items = [
        t("Hinweise zur optimalen Speed-Messung"),
        t("Hilfreiche WLAN Tipps"),
        t("Alles zum Thema LAN-Messung"),
        t("Einschätzung Deiner Geräte-Performance"),
        t("Browser-Check"),
        t("Netz-Ausbautermine bei Dir"),
    ];

    return (
        <RootDiv>
            <HeaderDiv>{t("Schnelle Tipps für Dich")}</HeaderDiv>
            <BodyDiv>
                {t("Du willst mehr wissen? Der Speedtest Guide hilft Dir weiter - mit passenden Tipps zu Deiner Messung.")}
            </BodyDiv>
            <ListDiv>
                <SubHeaderDiv>
                    {t("Deine Vorteile")}
                </SubHeaderDiv>
                {items.map((item, index) => (
                    <ItemDiv key={`item${index}`}>
                        <BulletImg src={checkSvg.src} />
                        {item}
                    </ItemDiv>
                ))}
            </ListDiv>
            <ButtonContainer>
                <NavigationButton onClick={() => dispatch(updateResultPageTab(ResultTabKey.SpeedtestGuide))}>
                    <RedButtonDiv>
                        {t("Meinen Speedtest Guide starten")}
                    </RedButtonDiv>
                </NavigationButton>
                <NavigationButton onClick={() => {
                    dispatch(startMeasurement3());
                }}>
                    <CommonButtonDiv>
                        {t("Speedtest erneut starten")}
                    </CommonButtonDiv>
                </NavigationButton>
            </ButtonContainer>
        </RootDiv>
    );
}

export default GuideCard;